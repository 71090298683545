import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useTheme } from 'styled-components';

import { handleIcons } from 'utils/modulesIcons';
import { useAuth } from 'hooks/Auth';
import { IModule } from 'utils/interfaces';
import { CardContainer } from 'app/styles';

interface IModuleProps {
  data: IModule;
  isModule?: boolean;
}

const Module: React.FC<IModuleProps> = ({ data, isModule }) => {
  const { token } = useAuth();
  const { title } = useTheme();

  const history = useHistory();

  const hash = localStorage.getItem('@pdamodules::refresh');
  const client = localStorage.getItem('@pdamodules::codigoCliente');
  const descricaoCliente = localStorage.getItem('@pdamodules::cliente');

  const tokenNoBar = token?.replaceAll('\\', '');
  const tokenData = tokenNoBar?.replace(/"/g, '');

  const exception: any = jwt_decode(String(tokenData));
  const exp = Number(exception.exp) * 1000;

  const handleModuleClick = useCallback(
    (module: IModule) => {
      const formatedHash = hash!.replace(/"/g, '');
      const formatedClient = client!.replace(/"/g, '');
      const formatedClientDescription = descricaoCliente!.replace(/"/g, '');

      localStorage.setItem(
        '@pdamodules::codigoSistema',
        JSON.stringify(module.codigoSistema)
      );

      if (module.sigla !== 'ADM') {
        window.location.replace(
          `${module.url}auth?token=${formatedHash}&codigoCliente=${formatedClient}&client=${formatedClientDescription}&exception=${exp}&code=${module.codigoSistema}&theme=${title}`
        );
      } else if (module.sigla === 'ADM') {
        history.push('/Profile/');
      } else {
        window.location.replace(module.url);
      }
    },
    [client, descricaoCliente, hash, history, exp, title]
  );

  return (
    <CardContainer
      isModule={isModule}
      type="button"
      onClick={() => handleModuleClick(data)}
    >
      <div className="iconBx">
        {isModule && handleIcons(data.sigla.toLowerCase())}
      </div>
      <span className="text">{data.descricaoSistema}</span>
    </CardContainer>
  );
};

export default Module;
