import styled, { keyframes } from 'styled-components';
import { Button } from '@material-ui/core';
import { Form } from '@unform/web';

const loadingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  from {
    transform: scale(0.95);
    opacity: 0.5;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const LoginContainer = styled.div`
  position: relative;
  width: 95%;
  max-width: 1105px;
  max-height: 750px;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 3rem;
  background: ${props => props.theme.colors.container_bg};
  display: flex;
  justify-content: center;
  animation: ${fadeIn} 0.25s forwards ease;
`;

export const ThemeToggleBox = styled.div`
  position: absolute;
  top: 12px;
  right: 8px;
`;

export const LoginWrapper = styled.div`
  width: 100%;
  max-width: 780px;
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    margin-bottom: 2rem;
  }
`;

export const LoginTitle = styled.h3`
  font-size: clamp(16px, 24px, 5vmin);
  font-weight: 500;
  color: ${props => props.theme.colors.title_color};
  text-align: center;
  margin: 3rem 0;
`;

export const FormWrapper = styled(Form)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  button {
    background-color: #0095e8 !important;
    color: #fff;
    text-transform: capitalize;
    font-family: 'Poppins' sans-serif;
    max-width: 345px;
    padding: 12px 8px;
    border-radius: 6px;
    width: 100%;
    min-height: 44px;
    display: grid;
    place-items: center;
    margin-top: 3rem;
    letter-spacing: 5%;
    font-weight: 400;
    box-shadow: none;
    svg {
      animation: ${loadingAnimation} 1.5s linear infinite;
    }
  }
`;

export const SubmitButton = styled(Button)`
  svg {
    animation: ${loadingAnimation} 1.5s linear infinite;
  }
`;
