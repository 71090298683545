/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@material-ui/core';

import { CardContainer } from '../../../app/styles';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';
import { IClient } from '../../../utils/interfaces';
import { ClientsBox } from '../styles';

const Clientes: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState<IClient[]>([]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { signOut } = useAuth();

  const getClients = useCallback(async () => {
    setIsLoading(true);

    await api
      .get('Usuario/Autenticado')
      .then(res => {
        if (res.data?.clientes.length > 1) {
          setClients(res.data.clientes);

          return;
        }

        const { descricaoCliente, codigoCliente } = res.data.clientes[0];

        if (codigoCliente) {
          localStorage.setItem(
            '@pdamodules::codigoCliente',
            JSON.stringify(codigoCliente)
          );
          localStorage.setItem(
            '@pdamodules::cliente',
            JSON.stringify(descricaoCliente)
          );
          history.push('/choose-module');
        }
      })
      .catch(err => {
        if (err.response?.status === 401) {
          signOut();
          return;
        }
        enqueueSnackbar(`Erro: ${err.message}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [history, signOut, enqueueSnackbar]);

  const handleClientClick = useCallback(
    (client: IClient) => {
      if (client.codigoCliente) {
        localStorage.setItem(
          '@pdamodules::codigoCliente',
          JSON.stringify(client.codigoCliente)
        );
        localStorage.setItem(
          '@pdamodules::cliente',
          JSON.stringify(client.descricaoCliente)
        );
        localStorage.setItem(
          '@pdamodules::codigoSistema',
          JSON.stringify(client.codigoSistema)
        );
        history.push('/choose-module');
      }
    },
    [history]
  );

  useLayoutEffect(() => {
    getClients();
  }, [getClients]);

  return (
    <ClientsBox>
      {isLoading ? (
        <CircularProgress color="inherit" />
      ) : clients.length ? (
        clients.map((client, index) => (
          <CardContainer key={index} onClick={() => handleClientClick(client)}>
            <div className="iconBx" />
            <span className="text">{client.descricaoCliente}</span>
          </CardContainer>
        ))
      ) : (
        <span>Não há nada</span>
      )}
    </ClientsBox>
  );
};

export default Clientes;
