export default {
  translations: {
    screens: {
      header: {
        hello: 'Hola',
        support: 'Soporte',
        help: 'Ayuda',
        about: 'Acerca',
      },
      profile: {
        title: 'Perfil',
        description: 'Descripción',
        isLeader: 'Eres un líder?',
        titleEdit: 'Sistemas',
        switchButtonEdit: 'Solo Líder',
        editSucces: '¡Perfil editado con éxito!',
        editProfile: 'Editar perfil:',
        createProfile: 'Crear Perfil',
        newProfile: 'Nuevo Perfil',
        create: 'Crear',
        systemsNullablw: 'Seleccione cualquier sistema para crear un perfil',
        createProfileSucces: '¡Perfil creado con éxito!',
        save: 'Ahorrar',
        menuDescription: 'Descripción del Menú',
        integrate: 'Integrar',
        saving: 'Ahorro',
        permissionsSaving: 'Permisos guardados',
        msgNoProfile: 'Sin registros de perfil',
      },
      profileTable: {
        code: 'Código',
        isLeader: 'Líder',
        actions: 'Comportamiento',
        edit: 'Editar',
        permissions: 'Permisos',
      },
      user: {
        userTitle: 'Usuarios',
        profile: 'Perfil',
        name: 'Nombre',
        isActive: '¿Está activo?',
        newUser: 'Nuevo Usuário',
        msgNoUser: 'Sin registros de usuario',
        resultFound: 'resultados(s) encontrados!',
        resetPassword: 'Restablecer la Contraseña',
        branchs: 'Sucursales',
        editPassword: 'Restablecimiento de contraseña con éxito',
        error: 'Error',
        editUser: 'Editar Usuario',
        createUser: 'Crear Usuario',
        createUserSucces: 'Usuário criado com sucesso!',
        IsNullBranch: '¡Seleccione cualquier sucursal para crear un usuario!',
        selected: 'Seleccionado',
        selectedSystem: 'Seleccione un sistema!',
        noRecordes: 'No hay registros',
      },
      userTable: {
        code: 'Código',
        login: 'Acesso',
        status: 'Estado',
        delete: 'Borrar',
      },
      userDelete: {
        inactivatedSucces: '¡Usuario desactivado con éxito!',
        userInativated: '¡Usuario inactivo!',
        wantToLeave: 'Querer irse',
        inactive: 'inactivo',
      },
    },
    components: {
      table: {
        advancedFilter: 'Filtros avanzados',
        lessFilter: 'Menos Filtros',
        loagind: 'Cargando',
        search: 'Buscar',
        clean: 'Limpiar',
        toAdd: 'Para agregar',
        fileUpload: 'Subir archivo',
        cancel: 'Cancelar',
        notFound: 'Ningún record fue encontrado',
        export: 'Exportar',
        recordes: 'Registros',
        to: 'el',
        toClose: 'Cerrar',
        confirm: 'Confirmar',
      },
    },
  },
};
